<template>
  <v-container class="overviewContainer">
    <v-row class="hidden-sm-and-down">
      <v-img
        height="300"
        src="/img/retailerCover.jpeg"
        style="overflow: visible"
      >
        <v-row wrap class="overlay">
            <v-col cols="12">
                <v-row wrap>
                <v-col cols="12" align="center">
                    <p class="bigHeader">Retailers Deals</p>
                </v-col>
                </v-row>

                <v-row class="mt-10" style="postion:absolute; left: 44%; top: 90% ">
                <v-col cols="3" class="headerBox ml-12">
                    <v-layout wrap>
                    <v-col cols="5" align="left">
                        <v-img
                        contain
                        :src="discountedProducts[0].productImagePath"
                        height="120"
                        />
                    </v-col>

                    <v-col cols="7" align="right">
                        <v-icon size="50" color="#72BD61">mdi-bookmark</v-icon>
                        <v-layout align="right" style="font-size:11px;" class="mt-4">
                        {{discountedProducts[0].name}}
                        </v-layout>
                        <v-layout wrap>
                        <v-col cols="6">
                            <h6>{{ systemSetting.systemCurrency }}{{discountedProducts[0].price}}</h6>
                        </v-col>
                        <v-col cols="6">
                            <span class="spanPrice">-{{discountedProducts[0].discount}}%</span>
                        </v-col>
                        </v-layout>
                    </v-col>
                    </v-layout>
                </v-col>

                <v-col cols="3" offset="1" class="headerBox" v-for="product in discountedProducts.slice(1,2)" :key="product.id">
                    <v-layout wrap>
                    <v-col cols="5" align="left">
                        <v-img
                        contain
                        :src="product.productImagePath"
                        height="120"
                        :to="'/product-detail/' + product.productId"
                        />
                    </v-col>

                    <v-col cols="7" align="right">
                        <v-icon size="50" color="#72BD61">mdi-bookmark</v-icon>
                        <v-layout align="right" style="font-size:11px;" class="mt-4">
                        {{ product.name }}
                        </v-layout>
                        <v-layout wrap>
                        <v-col cols="6">
                            <h6>{{ systemSetting.systemCurrency }}
                        {{ product.price }}</h6>
                        </v-col>
                        <v-col cols="6">
                            <span class="spanPrice">{{product.discount}}%</span>
                        </v-col>
                        </v-layout>
                    </v-col>
                    </v-layout>
                </v-col>

                </v-row>
            </v-col>
        </v-row>
      </v-img>
    </v-row>

    <v-container class="hidden-md-and-up">
      <v-row>
        <v-col cols="12">
          <h2 align="center">Season's Buys</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="headerBox">
          <v-layout wrap>
            <v-col cols="5" align="left">
              <v-img
                contain
                src="https://res.cloudinary.com/ecrtechdev/image/upload/v1632944202/5ec81f5e7964eoxynorm-20mg_2_ztgyxs.png"
                height="120"
              />
            </v-col>

            <v-col cols="7" align="right">
              <v-icon size="50" color="#72BD61">mdi-bookmark</v-icon>
              <v-layout align="right" style="font-size:11px;" class="mt-4">
                Furosemid 20mg is available in dozen
              </v-layout>
              <v-layout wrap>
                <v-col cols="6">
                  <h6>$2.11</h6>
                </v-col>
                <v-col cols="6">
                  <span class="spanPrice">-99%</span>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>

    <v-row class="mt-8">
      <v-col cols="12">
        <h2 align="center">New Arrivals</h2>
      </v-col>
    </v-row>

    <v-row class="mb-8">
      <v-col
        v-for="(product, index) in productLists"
        :key="index"
        cols="6"
        md="3"
        class="mt-3"
      >
        <v-sheet
                height="390"
                style="background-color:#FAFAFA;border-radius:10px;"
                elevation="3"
              >
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:340px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                <div class="mt-3 ml-3">
                  <h5 style="color:#81c24B;">
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5>
                  <p style="height:40px;font-size:14px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  
              
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      color="#000000"
                      style="color:#ffffff;"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "RetailersStores",
  data() {
    return {
      discountedProducts: [],
      productLists: [],
      systemSetting: {
        systemCurrency: "GH₵",
      },
    };
  },
  computed: {
    fiteredDiscounts: function() {
      return this.discountedProducts.filter(function(el) {
        return el.discount >= 1;
      });
    },
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          console.log(response.data.data);
          response.data.data.forEach(element => {
            if(element.User.Role.name == "Retailer"){
              this.productLists.push(element);
            }

            if(element.displayDiscount == true){
              this.discountedProducts.push(element);
            }
          });
        });
    },
  },
};
</script>
<style scoped>
.overviewContainer {
  background-color: #ffffff;
  padding: 10px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.bigHeader {
  font-size: 50px;
  color: #ffffff;
  margin-top: 20px;
}

.headerBox {
  border: 2px solid green;
  height: 200px;
  background-color: #ffffff;
  border-radius: 10px;
}

.headerBox2 {
  border: 1px solid green;
  height: 150px;
  background-color: #ffffff;
  border-radius: 10px;
}

.spanPrice {
  background-color: red;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 500;
}

.categoryDeals {
  background-color: #c4c4c4;
  height: 250px;
  margin-bottom: 100px;
  overflow: visible;
}

.priceStyle2 {
  width: 60px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 2px;
  border-bottom-right-radius: 5px;
}
</style>
 



